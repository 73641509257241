import '../../windowInterface'
import './fancybox'
import './helpers'
import './klaviyo_helpers'
import './meganav'
import 'swiper'
import { clampTags } from './tags'
import * as lazySizes from 'lazysizes'

lazySizes.init()
clampTags(2, '')

const mobileMenu = Array.from(document.getElementsByClassName('burger-toggle'))
mobileMenu.forEach((ele: Element) => {
  ele.addEventListener('click', (_) => {
    toggleHidden()
  })
})

function toggleHidden() {
  mobileMenu.forEach((ele: Element) => {
    if (ele.classList.contains('hidden')) {
      ele.classList.remove('hidden')
    } else {
      ele.classList.add('hidden')
    }
  })
  if (document.getElementById('mobile-menu')!.classList.contains('hidden')) {
    document.getElementById('mobile-menu')!.classList.remove('hidden')
    document.dispatchEvent(new Event('mobile-menu-opened'))
  } else {
    document.getElementById('mobile-menu')!.classList.add('hidden')
    document.dispatchEvent(new Event('mobile-menu-closed'))
  }
}
