import { Fancybox } from '@fancyapps/ui/dist/fancybox/fancybox.esm.js'

const options = {
  dragToClose: false,
  Toolbar: {
    display: {
      left: [],
      middle: [],
      right: ['iterateZoom', 'close']
    }
  }
}

Fancybox.bind('[data-fancybox]', options)
