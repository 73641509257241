import type { Artwork, Product } from '../../main/src/interfaces/ProductInterfaces'
import useHttps from '../main/src/hooks/useHttps'
import { flatten } from 'lodash-es'

const KLAVIYO_EVENT_URL = '/klaviyo/event'

function csrfToken() {
  return document.querySelector('meta[name=csrf-token]').content
}

window.KM.KlaviyoHelper = {
  trackAddProductToCart(product: Product, artwork: Artwork) {
    const { post } = useHttps()

    const payload = {
      $value: product.price!.valueOf() / 100,
      AddedItemArtistName: artwork.artist.full_name,
      AddedItemCategories: flatten(artwork.categories),
      AddedItemImageURL: product.image_url,
      AddedItemPrice: product.price! / 100,
      AddedItemProductID: artwork.id,
      AddedItemProductName: artwork.title,
      AddedItemProductTier: artwork.retail_tier_id,
      AddedItemQuantity: 1,
      AddedItemSKU: product.id,
      AddedItemURL: window.location.href,
      CheckoutURL: window.KM.Config.url + '/cart'
    }

    const body = { event: 'Added to Cart', payload: payload }
    const headers = { 'X-CSRF-Token': csrfToken() }
    return post(KLAVIYO_EVENT_URL, body, { headers: headers })
  }
}
