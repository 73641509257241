import Cookies from 'js-cookie'

window.KM = window.KM || {}
window.KM.Helpers = {
  getCookie(name) {
    return Cookies.get(name)
  },
  setCookie(name, value, options = {}) {
    return Cookies.set(name, value, options)
  }
}
